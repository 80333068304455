.it-header-slim-wrapper {
  background: $site-header-slim-bg-color;

  .it-header-slim-right-zone {
    .it-search-wrapper {
      > span.d-md-block {
        display: none !important;
      }

      .search-link {
        @extend .nav-link;
        border-right: 1px solid $header-center-bg-color;
        border-radius: 0;

        &:hover,
        &:active {
          background-color: $header-center-bg-color;
        }
      }
    }
  }
}
