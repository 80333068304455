#page-view-art12 {
  .PageHeaderWrapper {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

    &.full-width {
      height: unset;
    }
  }
}

.art12-section {
  margin: 2rem 0 2rem 0;
}

table.art12-table {
  width: 100%;
  padding: 0;
  margin: 1rem 0;
  background-color: white;
  border-collapse: collapse;
  clear: both;

  td,
  th {
    padding: 3px 0.5rem;
    border: 1px solid #ccc;
    border-collapse: collapse;

    &.bold {
      font-weight: bold;
    }

    &.highlited {
      color: $primary;
      font-weight: bold;
    }

    .right {
      float: right;
    }

    &.no-padding {
      padding: 0;

      > table {
        width: 100%;
        border: none;

        tr {
          td {
            &:first-of-type {
              border-top: none;
              border-left: none;
            }

            &:last-of-type {
              border-top: none;
              border-right: none;
            }
          }

          &:last-of-type {
            th,
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  th {
    width: 40%;
    background-color: #f6f6f6;

    &.header-3 {
      background-color: #ccc;
      font-weight: bold;
    }
  }
}
