#page-view-natura2000-sdf {
  .year-select-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;

    .form-control {
      width: auto;
      border-bottom: 2px solid $primary;
      box-shadow: none;
    }
  }
}

.sdf {
  padding: 2em 0;
  margin: 2em 0;
  font-family: Verdana, Arial, Sans-serif;
  font-size: 10pt;
  line-height: normal;

  .sdf-header {
    display: flex;

    .logo {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .title {
      font-size: 0.9em;
      font-weight: bold;

      h1 {
        margin-bottom: 0.2em;
        color: $primary;
        font-size: 2em;
      }
    }
  }

  .Bold {
    font-weight: bold;
  }

  .SDFtable {
    padding: 0;
    border: 1px solid black;
    margin: 10px 0 10px;
    background-color: White;
    border-collapse: collapse;
    color: Black;
    font-size: 10pt;

    &,
    tr,
    td {
      padding: 3px;
      border-width: 1px;
      border-style: dotted;
      border-collapse: collapse;
    }

    tbody,
    tr,
    th,
    td {
      border-color: #666666;
    }

    tr {
      padding: 0;
      margin: 0;
    }

    td {
      padding: 4px;
      border: 1px solid #cccccc;
      margin: 0;
      text-align: left;
    }
  }

  .SDFtableNoBorder {
    padding: 0;
    margin: 10px 0 10px;
    background-color: White;
    border-collapse: collapse;
    color: Black;
    font-size: 10pt;

    &,
    td,
    tr {
      padding: 3px;
      border-width: 1px;
      border-style: dotted;
      border-color: #000;
      border-collapse: collapse;
    }

    tbody,
    tr,
    th,
    td {
      border-color: #666666;
    }

    tr {
      padding: 0;
      margin: 0;
    }

    td {
      padding: 4px;
      border: 1px solid #cccccc;
      margin: 0;
      text-align: left;
    }
  }

  .SDFtable3rd {
    border: 1px solid #cccccc;
    margin: 0.5em 1em;
    color: #000000;
    font-family: Verdana, Arial, Sans-serif;
    font-size: 10pt;

    &,
    td,
    tr {
      padding: 3px;
      border-width: 1px;
      border-style: dotted;
      border-color: #000;
      border-collapse: collapse;

      tr {
        border-color: #666666;
      }

      th {
        padding: 0.4em;
        border: 1px solid #cccccc;
        background-color: #e3e3e3 !important;
        color: #000000;
        font-weight: bold;
        text-align: center;
        text-align: left;
      }

      td {
        padding: 0.2em;
        border: 1px solid #cccccc;
        margin: 0;
        text-align: left;
      }
    }
  }

  .HalfWidthTable {
    width: 47%;
    padding: 0;
    float: left;
  }

  table {
    width: 100%;

    &.TableWidthAuto {
      width: auto;

      table {
        width: auto;
      }
    }

    &.MinimalHeight {
      th,
      td {
        height: 40px;
      }
    }

    tr,
    th,
    td {
      &.MinimalHeight {
        height: 40px;
      }
    }
  }

  .sdf-body {
    .clear {
      clear: both;
    }

    .margin-left-1 {
      margin-left: 0.5rem !important;
    }

    .CheckType {
      display: block;
      width: 10px;
      width: 17px;
      height: 17px;
      padding: 0 3px;
      border: 1px solid Black;
      margin: 0 3px 0 0;
      float: left;
    }

    ul.legend {
      padding: 0;
      margin: 0 0 0 15px;
      list-style: none;
      list-style-type: none;

      li {
        padding: 0;
        margin: 0 0 3px 0;

        b {
          color: #535353;
        }
      }
    }

    .mapLegend {
      padding: 0;
      margin: 0 0 40px;
      clear: both;
      line-height: 18px;
    }

    .invisible-button {
      padding: 0;
      border: 0;
      margin: 0;
      background: none;
    }

    .siteData_title {
      padding-bottom: 2em;
      margin: 10px 0 0 0;
      clear: both;
    }

    h1 {
      margin-top: 1.5em;
      color: #000;
      font-size: 1.3em;
    }

    h2 {
      margin: 20px 0 3px;
      color: #000;
      font-size: 1.05em;
    }

    h3 {
      margin: 10px 0 3px;
      color: #000;
      font-size: 1em;
    }

    h4 {
      padding: 0;
      margin: 0;
      font-size: 0.8em;
    }

    a:link {
      color: $primary;
      font-size: 0.8em;
      font-weight: bold;
    }

    .BackTopLink {
      padding: 0;
      padding-right: 3%;
      margin: 0;
      float: right;
      text-align: right;
    }

    table {
      border: 1px solid #cccccc;
      margin: 0.5em 1em 0.5em 1em;

      &.noborder {
        margin: 0;

        &,
        tr,
        td,
        th {
          width: auto;
          border: none;
        }
      }

      &.no-border-collapse {
        &,
        tr,
        th,
        td {
          border-collapse: separate;
        }
      }
    }

    .more-margin {
      margin: '.5em 1em .5em 1em';
    }
  }
}
