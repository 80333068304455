#page-view-catalog {
  .classification {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $neutral-2-b2;
    margin-bottom: 1rem;

    .classification-header,
    .classification-body {
      padding: 0.5rem 1rem;
    }

    .classification-header {
      background-color: $primary;
      color: $primary-text;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }

    .taxonomy {
      display: flex;

      .title {
        flex: 0 1 100px;
        margin-right: 0.5rem;
        font-size: 0.8rem;
        line-height: 1.7rem;
      }
    }
  }
}
