#page-view-catalog {
  .sites-list {
    .link-list-wrapper {
      max-height: 250px;

      padding-bottom: 1rem;
      border-top: 1px solid $lightgrey-c2;
      border-bottom: 1px solid $lightgrey-c2;
      margin-bottom: 2rem;

      overflow-y: scroll;
    }

    .results-header {
      display: flex;
      align-items: flex-end;

      .results-info {
        flex: 1 1 auto;
        font-size: 0.8rem;
        font-style: italic;
      }
    }

    .results-list {
      margin-top: 1rem;

      .results-list-item {
        display: flex;
        align-items: center;
        padding: 0.25rem 1rem;

        border-bottom: 1px solid $neutral-1-a1;

        &:hover,
        &:active {
          background-color: $lightgrey-c2;
        }

        .item-name {
          flex: 1 1 auto;
        }

        .item-link {
          flex: 0 1 auto;
          margin-left: 1rem;

          .btn {
            &:hover,
            &:active {
              background-color: $primary;
              color: $primary-text;

              .icon {
                color: $primary-text;
                fill: $primary-text;
              }
            }
          }
        }
      }

      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        .results-list-item {
          flex-wrap: wrap;

          .item-name {
            flex: 1 1 100%;
          }

          .item-link {
            flex: 0 1 auto;
            margin: 0;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
