//site
$primary-h: 159 !default;
$primary-s: 99 !default;
$primary-b: 50 !default;

$secondary: #1f5b46;
$tertiary: #018e5d;
$tertiary-text: #fff;

$site-header-slim-bg-color: #016629;
$header-bg-color: $tertiary;
$header-center-bg-color: $header-bg-color;

$footer-bg-color: #3a3a3a;
$headings-color: #3a3a3a;

$bg-light-green: #d7edc9;
$light: #b9d5de;
//$info: $gray-300;
//$semibold: 600;

$argument-icon-color: $secondary;
$argument-icon-bg: rgba($secondary, 0.1);
