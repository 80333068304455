.searchCatalog {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  color: $secondary-text;

  .searchCatalog-background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $secondary;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(82, 82, 82, 0.25);
      box-shadow: inset 0px 10px 28px -10px #000,
        inset 0px -10px 28px -20px #000;
      content: '';
    }
  }

  h2 {
    color: $secondary-text !important;
    font-weight: 600;
  }

  .searchContainer {
    flex-direction: column;
    justify-content: space-between;
    justify-content: space-evenly;
    padding: 0px 12%;
    margin: 56px 0;

    @media (max-width: #{map-get($grid-breakpoints, lg) - 1px}) {
      padding: 0px 10px;
    }

    .inputSearch {
      flex: 1 1 auto;

      .react-select__control {
        min-height: 50px;
        padding: 0 0.7rem;
        border-bottom: none !important;
        border-bottom-left-radius: $border-radius !important;
        border-top-left-radius: $border-radius !important;
        color: #000;

        &:hover {
          border-bottom: none !important;
        }
      }

      .react-select__menu {
        color: #000;
      }

      .react-select__option {
        &:hover,
        &:active,
        &:focus {
          background-color: lighten($primary, 62);
        }
      }

      .custom-option {
        display: flex;

        .more {
          margin-left: 0.5rem;
          color: #ccc;

          font-size: 0.8rem;
          font-style: italic;
          line-height: 1.7rem;
          text-transform: capitalize;
        }
      }

      .css-b8ldur-Input {
        width: 100%;
      }

      .react-select__input {
        width: 100%;

        input {
          width: 100% !important;
          transition: none;
        }
      }
    }

    .searchbar {
      button {
        display: flex;
        width: 50px;
        flex: 0 1 auto;
        align-items: center;
        justify-content: center;
        border-width: 0px;
        background: $primary;

        &:hover,
        &:active {
          background-color: darken($primary, 7.5);
        }
      }
    }
  }

  .buttonsContainer {
    flex-wrap: wrap;

    .btn.btn-outline-light {
      border-color: #fff;
      box-shadow: inset 0 0 0 1px #fff;
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        background-color: #fff;
        color: #000;
      }
    }
  }
}
