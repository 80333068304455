*,
.public-ui {
  .block.highlitedContent {
    color: red;

    .card {
      .card-title {
        a {
          font-size: 2.5rem;
          line-height: 3.2rem;
        }
      }

      .card-text {
        color: $text-color;
        font-family: $font-family-sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .card-body {
        a.read-more {
          position: unset;
          margin-top: 2rem;

          @extend .btn;
          @include button-variant($primary, $primary);

          svg.icon {
            color: $white;
            fill: $white;
          }
        }
      }
    }

    .bg-primary,
    .bg-secondary {
      .card {
        .card-body {
          a.read-more {
            @include button-variant($white, $white);
            color: $black;

            svg.icon {
              color: $black;
              fill: $black;
            }
          }
        }
      }
    }
  }
}
