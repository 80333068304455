.it-header-center-wrapper {
  height: 110px;

  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      a {
        .icon {
          width: auto;
          height: 100px;
        }
      }
    }

    .it-right-zone {
      .loghi {
        .icon {
          width: auto;
          height: 48px;
        }

        a + a {
          margin-left: 0.7em;
        }
      }

      .loghi + .it-socials {
        padding-left: 1em;
        border-left: 1px solid #4089a0;
        margin-left: 1em;
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-center-wrapper {
    height: 150px;
    padding-top: 0;

    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        a {
          .icon {
            height: 140px;
          }
        }
      }

      .it-right-zone {
        .loghi {
          .icon {
            height: 70px;
          }
        }
      }
    }
  }
}
@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        a {
          .icon {
            height: 48px;
          }
        }
      }
    }
  }
}
