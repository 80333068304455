#page-view-catalog {
  .info-text {
    font-size: 0.8rem;
  }

  .PageHeaderWrapper {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

    &.full-width {
      height: unset;
    }
  }

  .category {
    display: flex;
    font-size: 0.8rem;
    font-weight: bold;

    span {
      padding: 0 0.5rem;

      &:first-of-type {
        padding-left: 0;
      }
    }

    span + span {
      border-left: 2px solid $primary;
    }
  }

  .header-data {
    font-size: 0.8rem;
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn-icon {
    .icon {
      margin-right: 0.25rem;
    }
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    justify-items: center;
    text-align: center;
  }

  .rdt_Table {
    .rdt_TableHeadRow {
      background-color: $neutral-1-a1;

      .rdt_TableCol {
        font-weight: bold;
      }
    }

    #row-filters-row {
      &:hover {
        background-color: unset;
      }

      select,
      input {
        width: 100%;
      }
    }
  }
}
