.watchings {
  .filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;

    .filter {
      flex: 1 1 33%;
      padding: 0 0.25rem;
      font-size: 0.8rem;

      select,
      input {
        width: 100%;
      }
    }
  }

  .info-text {
    margin-top: 1rem;

    .icon {
      height: 1rem;
      margin-right: 0.5rem;
    }
  }

  .download-infos {
    align-self: center;
    font-size: 0.8rem;
    font-style: italic;
  }
}
