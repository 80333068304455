#page-view-catalog {
  .images {
    .description {
      font-size: 0.8rem;
    }

    .slick-slide {
      height: auto; // ← that must not be ignored
    }

    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
    }

    figure {
      img {
        max-height: 400px;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          max-height: 200px;
        }
      }
    }
  }
}
