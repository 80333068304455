.navbar {
  .navbar-collapsable {
    &,
    .menu-wrapper {
      .it-brand-wrapper {
        padding: 1rem;
        background-color: $header-center-bg-color;

        .it-brand-text {
          .no_toc {
            color: $primary-text;
          }
          @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            display: none;
          }
        }

        .icon {
          width: auto;
          height: 80px;
        }
      }
    }
  }
}
