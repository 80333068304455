.it-footer-main {
  background-color: $footer-bg-color;

  .border-top.border-white {
    border-color: $neutral-2-b3 !important;
  }
}

.it-footer .it-footer-main {
  .it-brand-wrapper {
    a .icon {
      width: auto;
      height: 75px;
    }

    .it-brand-text {
      display: none;
    }
  }
}

.it-footer-small-prints {
  background-color: $footer-bg-color;

  .it-footer-small-prints-list {
    border-top: 1px solid $neutral-2-b3;
  }
}
