.bg-light-green {
  background-color: $bg-light-green !important;
}

.btn {
  vertical-align: middle;

  .progress-spinner {
    display: inline-block;
    margin-right: 0.5rem;
  }

  &.btn-xs {
    .progress-spinner {
      width: 18px;
      height: 18px;
    }
  }
}

.tooltip {
  font-size: 0.75rem;
}
