@import '@italia/../theme/_site-variables.scss';
@import 'variables';
@import '@italia/../theme/site.scss';

body.cms-ui .content-area .public-ui,
.public-ui {
  @import 'custom/main';
}

.public-ui {
  /*import here your site customization styles*/
  @import 'custom/bootstrap-italia/custom/headerslim';
  @import 'custom/bootstrap-italia/custom/headercenter';
  @import 'custom/ItaliaTheme/components/mobilemenu';
  @import 'custom/bootstrap-italia/custom/footer';
}

@import 'custom/ItaliaTheme/components/Blocks/highlitedContent';

@import 'custom/site/cms';
@import 'custom/site/secondarymenu';
@import 'custom/site/Blocks/searchCatalog';

@import 'custom/site/View/CatalogView/catalogView';
@import 'custom/site/View/CatalogView/classification';
@import 'custom/site/View/CatalogView/conservationStatus';
@import 'custom/site/View/CatalogView/watchings';
@import 'custom/site/View/CatalogView/sitesList';
@import 'custom/site/View/CatalogView/images';
@import 'custom/site/View/Natura2000/SDF/view';
@import 'custom/site/View/Natura2000/SDF/sdf';
@import 'custom/site/View/Art12/art12View';
