#page-view-catalog {
  .conservation-status {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .code {
      width: 3rem;
      height: 3rem;

      margin-right: 0.5rem;
      border-radius: 100%;

      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2.9rem;
      text-align: center;
    }

    &.EX {
      .code {
        background-color: #000;
        color: red;
      }
    }

    &.EW {
      .code {
        background-color: #000;
        color: #fff;
      }
    }

    &.CR {
      .code {
        background-color: #cc3433;
        color: #ffcdcd;
      }
    }

    &.EN {
      .code {
        background-color: #cd6631;
        color: #ffcd9a;
      }
    }

    &.VU {
      .code {
        background-color: #cd9a01;
        color: #fff;
      }
    }

    &.NT {
      .code {
        background-color: #006667;
        color: #9acd9a;
      }
    }

    &.LC {
      .code {
        background-color: #026767;
        color: #fff;
      }
    }
  }
}
