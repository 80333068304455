#page-view-natura2000-sdf {
  .PageHeaderWrapper {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

    &.full-width {
      height: unset;
    }
  }

  .btn-icon {
    .icon {
      margin-right: 0.25rem;
    }
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    justify-items: center;
    text-align: center;
  }
}

.back-button {
  margin: 2rem 0;
  text-align: right;
}

@media print {
  .section-Natura2000.section-SDF {
    .PageHeaderWrapper,
    .it-header-wrapper {
      display: none;
    }

    table,
    tr,
    td {
      page-break-inside: avoid;
    }
  }
}
